import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-prestige-classes"></a><h2>Epic Prestige Classes
    </h2>
    <a id="union-sentinel"></a><h3>UNION SENTINEL</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery6/44283_C6_Sentinel.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d10.
    <a id="union-sentinel-requirements"></a><h6>Requirements</h6>
To qualify to become a Union Sentinel, a character must
fulfill all the following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Alignment:</span> Any lawful.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Base Attack Bonus:</span>
+21. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
8 ranks, <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (local) 8 ranks. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#improved-disarm" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Disarm</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span> <a href="epicFeats.html#armor-skin" style={{
        "color": "rgb(87, 158, 182)"
      }}>Armor
Skin</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Must reside
in a demiplane-city. </p>
    <p style={{
      "fontWeight": "bold"
    }}>Class Skills</p>
    <p>The Union Sentinel&#8217;s class skills (and the key ability for
each skill) are <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> (Cha), <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a>
(Cha), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (local) (Int), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
(Wis), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> (Int), <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a> (Wis), and <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> (Wis). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
2 + Int modifier. </p>
    <a id="table-the-union-sentinel"></a><span style={{
      "fontWeight": "bold"
    }}>
      <p>{`Table: The Union Sentinel `}</p>
    </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>1st
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Sending</span> 1/day, <span style={{
              "fontStyle": "italic"
            }}>shield of law</span> 1/day
          </td>
        </tr>
        <tr>
          <td>2nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Freedom</span> 1/day, <span style={{
              "fontStyle": "italic"
            }}>knock</span> 1/day</td>
        </tr>
        <tr className="odd-row">
          <td>3rd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Dimensional anchor</span> 1/day</td>
        </tr>
        <tr>
          <td>4th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Portal
guardian 1/day, <span style={{
              "fontStyle": "italic"
            }}>shield of law</span>
2/day</td>
        </tr>
        <tr className="odd-row">
          <td>5th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Forcecage</span> 1/day</td>
        </tr>
        <tr>
          <td>6th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Dimensional anchor</span> 2/day, <span style={{
              "fontStyle": "italic"
            }}>knock</span> 2/day</td>
        </tr>
        <tr className="odd-row">
          <td>7th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Imprisonment</span> 1/day, <span style={{
              "fontStyle": "italic"
            }}>shield of law</span> 3/day</td>
        </tr>
        <tr>
          <td>8th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Freedom</span> 2/day, <span style={{
              "fontStyle": "italic"
            }}>sending</span> 2/day</td>
        </tr>
        <tr className="odd-row">
          <td>9th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Dimensional anchor</span> 3/day</td>
        </tr>
        <tr>
          <td className="last-row">10th
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Knock</span>
3/day, <span style={{
              "fontStyle": "italic"
            }}>forcecage</span> 2/day,
portal guardian 2/day, <span style={{
              "fontStyle": "italic"
            }}>shield of law</span>
4/day</td>
        </tr>
      </tbody>
    </table>
    <a id="union-sentinel-class-features"></a><h6>Class Features</h6>
The following are class features of the Union Sentinel prestige class. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Union Sentinels are proficient with all simple and martial weapons, all
armor, and all shields. </p>
    <a id="union-sentinel-sending"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Sending
(Sp):</span> At 1st level, the Union Sentinel gains the use of the
sending spell as a spell-like ability once per day, plus one additional
time per day every seven levels thereafter. This ability functions as
the spell cast by a 15th-level caster. </p>
    <a id="union-sentinel-shield-of-law"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Shield of
Law (Sp):</span> At 1st level, the Union Sentinel gains the use of the
shield of law spell as a spell-like ability once per day, plus one
additional time per day every three levels thereafter. This ability
functions as the spell cast by a 15th-level caster. </p>
    <a id="union-sentinel-freedom"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Freedom
(Sp):</span> At 2nd level, the Union Sentinel gains the use of the
freedom spell as a spell-like ability once per day, plus one additional
time per day every six levels thereafter. This ability functions as the
spell cast by an 18th-level caster. </p>
    <a id="union-sentinel-knock"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Knock
(Sp):</span> At 2nd level, the Union Sentinel gains the use of the
knock spell as a spell-like ability once per day, plus one additional
time per day every four levels thereafter. This ability functions as
the spell cast by a 15th-level caster. </p>
    <a id="union-sentinel-dimensional-anchor"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Dimensional
Anchor (Sp):</span> At 3rd level, the Union Sentinel gains the use of
the dimensional anchor spell as a spell-like ability once per day, plus
one additional time per day every three levels thereafter. This ability
functions as the spell cast by a 15th-level caster. </p>
    <a id="union-sentinel-portal-guardian"></a><p><span style={{
        "fontWeight": "bold"
      }}>Portal Guardian (Su):</span>
A 4th-level Union Sentinel can become a portal guardian once per day,
plus one additional time per day every six levels thereafter. The Union
Sentinel must position him or herself within 5 feet of a portal or
gate, and while he or she remains, the portal cannot be activated from
either side by any means. The Union Sentinel can defend him or herself
and use any of his or her other abilities normally, as long as he or
she remains adjacent to the blocked portal. This ability only functions
against portals and the gate spell, not against creatures with other
spell-like or supernatural interplanar traveling abilities. </p>
    <a id="union-sentinel-forcecage"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Forcecage
(Sp):</span> At 5th level, the Union Sentinel gains the use of the
forcecage spell as a spell-like ability once per day, plus one
additional time per day every five levels thereafter. This ability
functions as the spell cast by a 20th-level caster. </p>
    <a id="union-sentinel-imprisonment"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Imprisonment
(Sp):</span> At 7th level, the Union Sentinel gains the use of the
imprisonment spell as a spell-like ability once per week, plus one
additional time per week every five levels thereafter. This ability
functions as the spell cast by a 20th-level caster.&nbsp;</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      